.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  padding: 0;
  background-color: #121212;
  /* Almost black background */
  font-family: Arial, sans-serif;
  color: #ffffff;
  /* White text color */
}

// App.scss
$background-color: #1F1F1F;
$text-color: #EAEAEA;
$accent-color-1: #13EF93;
$accent-color-2: #149AFB;
$secondary-text-color: #CBCCCF;

.landing-page {
  background-color: $background-color;
  color: $text-color;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;

  header {
    text-align: center;
    margin-bottom: 2rem;

    h1 {
      font-size: 3rem;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.5rem;
      color: $secondary-text-color;
    }
  }

  main {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    .box-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 2rem;

      .box {
        background-color: lighten($background-color, 5%);
        border-radius: 8px;
        padding: 2rem;
        text-align: center;
        text-decoration: none;
        color: $text-color;
        transition: transform 0.3s ease;
        width: 300px;

        &:hover {
          transform: translateY(-5px);
        }

        h2 {
          font-size: 2rem;
          margin-bottom: 1rem;
        }

        p {
          font-size: 1.2rem;
          color: $secondary-text-color;
        }
      }


      .box1 {
        border: 2px solid $accent-color-1;
      }

      .box2 {
        border: 2px solid $accent-color-2;
      }

      .box3 {
        border: 2px solid mix($accent-color-1, $accent-color-2);
      }

      .box4 {
        border: 2px solid $accent-color-1;
      }

    }
  }

  footer {
    text-align: center;
    color: $secondary-text-color;
    margin-top: 2rem;
  }
}